import { Panel } from 'primereact/panel';
import { MainMenuBar } from "../components/MainMenuBar";

export default function RoadmapLanding() {
    return (
        <>
            <MainMenuBar />
            <Roadmap />
        </>
    )
}

function Roadmap() {
    return (
        <Panel header="Roadmap">
            <ul>
                <li>Notification/Alert System: allow user to sign up and get insight delivered to their mailbox</li>
                <li>Feature Addition/Enhancement: a lot more features on the way... I don't have all the time to code them all, so priority will depend on your feedback!</li>
                <li>Better data source: if I have money... I want to switch to a data source that provides more accurate data on flights</li>
            </ul>
            <p className="m-0">
                The update here might be delayed... so please follow me on social media for latest update on Airgeek, what's coming next and what's on top of my mind.
            </p>
            <a href="https://twitter.com/airgeek96642400">
                <i className='pi pi-twitter m-2' style={{ fontSize: '2rem' }}></i>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61550268232318">
                <i className='pi pi-facebook m-2' style={{ fontSize: '2rem' }}></i>
            </a>
        </Panel>
    )
}