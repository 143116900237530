import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";

import { MainMenuBar } from '../components/MainMenuBar';
import { FunFlightSearchCard } from "../components/FunFlightSearchCard";
import { checkInputIataCode, formatDateToYYYYMMDD } from "../utils/InputUtils";
import { getFlightsByTime } from "../services/FlightService";
import { FlightTable } from "../tables/FlightTable";
import { getFunFlight } from "../services/FunFlightService";
import { getCancelledFlight } from "../services/AirportService";
import { FunFlightTableFilter } from "../components/FunFlightTableFilter";

export default function FlightLanding() {
    const { user, isLoading } = useAuth0();
    const isAuthenticated = true;

    const [city, setCity] = useState("");
    const [date, setDate] = useState(formatDateToYYYYMMDD(new Date()));

    const [serviceSelection, setServiceSelection] = useState({
        specialLivery: true,
        wideBody: false,
        newFlight: false,
        cancelled: false,
        significantlyDelayed: false,
        includeGateRunway: false
    });

    // Returned data
    const [flightData, setFlightData] = useState();
    const [flightSearchError, setFlightSearchError] = useState();
    const [filteredData, setFilteredData] = useState();

    // Searching
    const [flightSearchInProgress, setFlightSearchInProgress] = useState();

    async function handleSubmitClick() {
        console.log('Submitting: ' + city + date);

        // Reset except for input
        setFlightData(null)
        setFlightSearchError(null)
        setFlightSearchInProgress("Searching...")

        if (!checkInputIataCode(city)) {
            setFlightSearchError("Error: Please check airport input");
            setFlightSearchInProgress(null);
            return;
        }

        if (Object.values(serviceSelection).filter(value => value === true).length == 0) {
            setFlightSearchError("Error: Please select at least 1 option to continue");
            setFlightSearchInProgress(null);
            return;
        }

        var requestBody = Object.fromEntries(
            Object.entries(serviceSelection).map(([key, value]) => [key, value])
        );
        requestBody['city'] = city;
        requestBody['date'] = date;
        requestBody.cancelled = false; // Use a separate call for cancelled
        var flightResponse = await getFunFlight(requestBody);

        // var flightResponse = [{
        //     "flightNumber": "VS 105",
        //     "originOrDestination": "LHR",
        //     "aircraft": "Boeing 787-9",
        //     "departureOrArrivalTime": "2023-08-24 13:55",
        //     "gate": "S9",
        //     "direction": "arrival",
        //     "reg": "",
        //     "livery": ""
        // }]
        setFlightSearchInProgress(null)

        if (flightResponse == null) setFlightSearchError("Error: Try again");
        else {
            setFlightData(flightResponse);
            setFilteredData(flightResponse);
        }

        if (serviceSelection.cancelled) {
            setFlightSearchInProgress("Searching...")

            var cancelledResponse = await getCancelledFlight(city, date);
            setFlightSearchInProgress(null)
            if (cancelledResponse) {
                var data = flightResponse ? flightResponse.concat(cancelledResponse) : cancelledResponse
                setFlightData(data);
                setFilteredData(data);
            }
        }
    }

    async function handleServiceSelectionChange(e) {
        if (e.checked) {
            setServiceSelection((prevSelection) => ({ ...prevSelection, [e.value]: true }))
        } else {
            setServiceSelection((prevSelection) => ({ ...prevSelection, [e.value]: false }))
        }
    }

    function isSelectionCountMoreThanOne() {
        return Object.values(serviceSelection).filter(value => value === true).length > 1;
    }

    return (
        <>
            <MainMenuBar />
            <FunFlightSearchCard serviceSelection={serviceSelection} setServiceSelection={setServiceSelection} handleServiceSelectionChange={handleServiceSelectionChange} isSelectionCountMoreThanOne={isSelectionCountMoreThanOne}
                setCity={setCity} date={date} setDate={setDate} searchInProgress={flightSearchInProgress} handleSubmitClick={handleSubmitClick} flightSearchError={flightSearchError} />
            {flightData && flightData != null && !flightSearchError &&
                <>
                    <FunFlightTableFilter className="mt-3" data={flightData} setFilteredData={setFilteredData}></FunFlightTableFilter>
                    <FlightTable data={filteredData} includeGateRunway={serviceSelection.includeGateRunway} className="mt-4" />
                </>}
        </>
    )
}