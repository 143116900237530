import React, { useState, useEffect } from 'react';
import { InputText } from "primereact/inputtext";
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';

import Papa from 'papaparse';
import { checkInputIataCode } from '../utils/InputUtils';

export function AirportInput({ setCity, title }) {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [filteredOptions, setFilteredOptions] = useState(null)

    useEffect(() => {
        fetch('/airports.csv') // Use the relative path to the CSV file
            .then(response => response.text())
            .then(data => {
                const parsedData = Papa.parse(data, { header: true }).data;
                const parsedOptions = parsedData.map(item => ({
                    label: `${item.code} - ${item.name}`,
                    value: item.code,
                }));
                setOptions(parsedOptions);
            });
    }, []);

    return (
        <div className="card flex">
            <div className="flex-column">
                {/* <InputText id="airport-input" aria-describedby="airport-input-help" onChange={(e) => setCity(e.target.value)}/> */}
                <AutoComplete
                    value={selectedOption}
                    suggestions={filteredOptions}
                    placeholder={title ? title : 'Airport name or code'}
                    completeMethod={(e) => {
                        const query = e.query.toLowerCase();
                        var filteredAirports = options.filter((option) => {
                            return option.label.toLowerCase().startsWith(query) || option.label.toLowerCase().split('-')[1]?.trim().startsWith(query)
                        }
                        );
                        setFilteredOptions(filteredAirports);
                    }}
                    field="label"
                    onChange={(e) => {
                        setSelectedOption(e.value);
                        setCity(e.value.value ?? e.value)
                    }}
                />
            </div>
        </div>
    )
}