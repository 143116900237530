import React, { useState } from "react";
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { Tooltip } from 'primereact/tooltip';
import { DateSelector } from './DateSelector';
import { AirportInput } from './AirportInput';
import { LogInButton } from "./LogInButton";
import { SubmitButton } from "./SubmitButton";
import { useAuth0 } from "@auth0/auth0-react";

export function FunFlightSearchCard({ serviceSelection, handleServiceSelectionChange, isSelectionCountMoreThanOne, setCity, date, setDate, searchInProgress, handleSubmitClick, flightSearchError }) {
    const { isAuthenticated } = useAuth0();

    return (
        <div className="card justify-content-center mt-1">
            <Card title="Explore Fun Flights" subTitle="Search for fun flight globally with criteria">
                <SearchInput setCity={setCity} date={date} setDate={setDate} />
                <SearchFilter handleServiceSelectionChange={handleServiceSelectionChange} serviceSelection={serviceSelection} isSelectionCountMoreThanOne={isSelectionCountMoreThanOne} />
                <SubmitButton disabled={isSelectionCountMoreThanOne() && !isAuthenticated} searchInProgress={searchInProgress} handleSubmitClick={handleSubmitClick} />
                {flightSearchError && <Message severity="error" text={flightSearchError} className="mt-2" />}
            </Card>
        </div>
    )
}

function SearchInput({ setCity, date, setDate }) {
    return (
        <div className="flex flex-column gap-1 container">
            <DateSelector date={date} setDate={setDate} />
            <AirportInput setCity={setCity} />
        </div>
    )
}

function SearchFilter({ handleServiceSelectionChange, serviceSelection, isSelectionCountMoreThanOne }) {
    const { isAuthenticated } = useAuth0();

    const selectionWarning = (
        <div className="p flex align-items-center gap-2">
            <LogInButton size="small" /> for multiple
        </div>
    )

    const advancedInfo = (
        <div className="p flex align-items-center gap-2">
            <LogInButton size="small" />
        </div>
    )

    const specialLiveryTooltipContent = "Airlines paint select aircraft in special paint jobs, known as liveries, which are themed and often promote a partnership, honor a past, or express an airline's values"
    const wideBodyTooltipContent = "Widebody aircrafts like Airbus A350 and Boeing B777"
    const significantlyDelayedTooltipContent = "Flights that are delayed more than 3 hours"
    const cancelledFlightTooltipContent = "Flight that are already past the scheduled time may be excluded. Expect longer search time"
    const newlyStartedFlightTooltipContent = "Flights with a flight number didn't depart from this airport in the past 4 days"
    const includeGateRunwayTooltipContent = "Include gate info in the result. Caution: selecting this will make search longer"


    return (
        <>
            <div className="flex align-items-center gap-2 mt-4">
                <b>Choose at least 1 option:</b>
                {isSelectionCountMoreThanOne() && !isAuthenticated &&
                    <Message severity="warn" content={selectionWarning} className="p-1 align-items-center" />
                }
            </div>
            <div className="flex flex-wrap row-gap-1 column-gap-3 mt-2" style={{ fontSize: '13px' }}>
                <div className="flex align-items-center">
                    <Checkbox inputId="specialLivery" name="serviceSelection" value="specialLivery" onChange={handleServiceSelectionChange} checked={serviceSelection.specialLivery} />
                    <label htmlFor="specialLivery" className="ml-2">Special Livery</label>
                    <i className="special-livery-icon pi pi-question-circle ml-2" data-pr-position="right" data-pr-at="right+5 top" data-pr-tooltip={specialLiveryTooltipContent}></i>
                    <Tooltip target=".special-livery-icon" />
                </div>

                <div className="flex align-items-center">
                    <Checkbox inputId="wideBody" name="serviceSelection" value="wideBody" onChange={handleServiceSelectionChange} checked={serviceSelection.wideBody} />
                    <label htmlFor="wideBody" className="ml-2">Wide Body</label>
                    <i className="wide-body pi pi-question-circle ml-2" data-pr-position="right" data-pr-at="right+5 top" data-pr-tooltip={wideBodyTooltipContent}></i>
                    <Tooltip target=".wide-body" />
                </div>
                <div className="flex align-items-center">
                    <Checkbox inputId="significantlyDelayed" name="serviceSelection" value="significantlyDelayed" onChange={handleServiceSelectionChange} checked={serviceSelection.significantlyDelayed} />
                    <label htmlFor="significantlyDelayed" className="ml-2 mb-0">3 Hours+ Delayed</label>
                    <i className="significantly-delayed pi pi-question-circle ml-2" data-pr-position="right" data-pr-at="right+5 top" data-pr-tooltip={significantlyDelayedTooltipContent}></i>
                    <Tooltip target=".significantly-delayed" />
                </div>

                <div className="flex align-items-center">

                    <Checkbox inputId="cancelled" name="serviceSelection" value="cancelled" onChange={handleServiceSelectionChange} checked={serviceSelection.cancelled} />
                    <label htmlFor="cancelled" className="ml-2 mb-0">Cancelled</label>
                    <i className="cancelled pi pi-question-circle ml-2" data-pr-position="right" data-pr-at="right+5 top" data-pr-tooltip={cancelledFlightTooltipContent}></i>
                    <Tooltip target=".cancelled" />
                </div>
            </div >


            {/* Advanced Filter Section */}
            <div className="p mt-2 flex align-items-center gap-2">
                {!isAuthenticated && <><i className="pi pi-lock"></i>
                    <b>Log in Required for below</b>
                    <Message severity="info" content={advancedInfo} className="p-1 align-items-center" />
                </>}
                {isAuthenticated && <><i className="pi pi-lock-open"></i>
                    <b>You are logged In</b></>}
            </div>

            <div className="flex flex-wrap row-gap-1 column-gap-3 mt-2 " style={{ fontSize: '13px' }}>
                <div className="flex align-items-center">
                    <Checkbox inputId="newFlight" name="serviceSelection" value="newFlight" onChange={handleServiceSelectionChange} checked={serviceSelection.newFlight} disabled={!isAuthenticated} />
                    <label htmlFor="newFlight" className="ml-2 mb-0">Unique Flight Today</label>
                    <i className="newly-started pi pi-question-circle ml-2" data-pr-position="right" data-pr-at="right+5 top" data-pr-tooltip={newlyStartedFlightTooltipContent}></i>
                    <Tooltip target=".newly-started" />
                </div>
                <div className="flex align-items-center">
                    <Checkbox inputId="includeGateRunway" name="serviceSelection" value="includeGateRunway" onChange={handleServiceSelectionChange} checked={serviceSelection.includeGateRunway} disabled={!isAuthenticated} />
                    <label htmlFor="includeGateRunway" className="ml-2 mb-0">Include Gate</label>
                    <i className="includeGateRunway pi pi-question-circle ml-2" data-pr-position="right" data-pr-at="right+5 top" data-pr-tooltip={includeGateRunwayTooltipContent}></i>
                    <Tooltip target=".includeGateRunway" />
                </div>
            </div>
        </>
    )
}



