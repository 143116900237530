import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";

import { MainMenuBar } from "../components/MainMenuBar";
import { RouteMaretShareCard } from "../components/RouteMarketShareCard";
import { getRouteMarketShare } from "../services/RouteService";
import { checkInputIataCode, formatDateToYYYYMMDD } from "../utils/InputUtils";
import { MarketShareTable } from "../tables/MarketShareTable";


export default function RouteMaretShareLanding() {
    const { user, isLoading } = useAuth0();
    const isAuthenticated = true;

    const [dep, setDep] = useState("");
    const [arr, setArr] = useState("");
    const [date, setDate] = useState(formatDateToYYYYMMDD(new Date()));

    // Returned data
    const [data, setData] = useState();
    const [searchError, setSearchError] = useState();


    // Searching
    const [searchInProgress, setSearchInProgress] = useState();

    async function handleSubmitClick() {
        console.log('Submitting: ' + dep + arr + date);
        setSearchInProgress("Checking quota...")

        // Reset except for input
        setData(null)
        setSearchError(null)
        setSearchInProgress("Searching...")

        if (!checkInputIataCode(dep) || !checkInputIataCode(arr)) {
            setSearchError("Error: Please check your input");
            setSearchInProgress(null);
            return;
        }

        var flightResponse = await getRouteMarketShare(dep, arr, date);

        // var flightResponse = [{
        //     "flightNumber": "VS 105",
        //     "originOrDestination": "LHR",
        //     "aircraft": "Boeing 787-9",
        //     "departureOrArrivalTime": "2023-08-24 13:55",
        //     "gate": "S9",
        //     "direction": "arrival",
        //     "reg": "",
        //     "livery": ""
        // }]

        setSearchInProgress(null)

        if (flightResponse == null) setSearchError("Error: Try again");
        else {
            setData(flightResponse);
        }
    }

    return (
        <>
            <MainMenuBar />
            <RouteMaretShareCard setDep={setDep} setArr={setArr} date={date} setDate={setDate} searchInProgress={searchInProgress} searchError={searchError} handleSubmitClick={handleSubmitClick} />
            {data && data != null && !searchError &&
                <MarketShareTable data={data} className="mt-4" />}
        </>
    )
}