import { Panel } from 'primereact/panel';
import { MainMenuBar } from "../components/MainMenuBar";

export default function AboutLanding() {
    return (
        <>
            <MainMenuBar />
            <WhatIsAirGeek />
            <WhoAmI />
            <WhyIBuild />
        </>
    )
}

function WhatIsAirGeek() {
    return (
        <Panel header="What Is Airgeek?">
            <p className="m-0">
                Airgeek is a collection of tools that provide hidden insight into aviation. Airgeek's mission is to unveil the unexplored side of aviation that no other tools on the market is able to uncover.
                It's built by Airgeek, and for Airgeek + public alike.
            </p>
        </Panel>
    )
}

function WhoAmI() {
    return (
        <Panel header="Who Am I?">
            <p className="m-0">
                I am a software engineer at a tech company, an AvGeek, a railway geek and a photographer. I currently live in Seattle, Washington, USA.
            </p>
        </Panel>
    )
}

function WhyIBuild() {
    return (
        <Panel header="Why I Build Airgeek?">
            <p className="m-2">
                Being a passionate aviation enthusiast, I've always found myself intrigued by the lesser-known aspects of the airline industry.
                While I'm a dedicated user of established tools like FlightRadar24 and LiveATC, I've always yearned for more comprehensive insights beyond what these tools offer.
                Despite making numerous feature requests to FlightRadar24 to satisfy my curiosity, I regrettably received limited feedback. </p>
            <p className="m-2">The true impetus behind developing this toolbox emerged when I faced the challenge of pinpointing locations to spot widebody and special livery aircraft at Sea-Tac.
                Frustratingly, there were no tools available to assist in this endeavor, leaving me with the option of wandering around the terminal.
                It was in that moment that I resolved to create a solution, one that empowers individuals like myself to proactively locate these aircraft both in advance and in real-time.</p>
            <p className="m-2">As I embarked on the journey of crafting this toolbox, it opened up a whole new realm of possibilities and sparked a continuous flow of fresh ideas.
                In no time, I discovered myself creating an array of tools designed to uncover intriguing and fun facts about the airline industry.
                As I expand and refine this toolbox, I extend a heartfelt invitation to every aviation enthusiast to join me in this exciting journey.
            </p>
        </Panel>
    )
}