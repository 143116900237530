import React, { useState } from "react";
import { SelectButton } from 'primereact/selectbutton';
import { formatDateToYYYYMMDD } from "../utils/InputUtils";

export const todayDate = formatDateToYYYYMMDD(new Date())
const yesterdayDate = formatDateToYYYYMMDD(new Date(Date.now() - 86400000))
const tomorrowDate = formatDateToYYYYMMDD(new Date(Date.now() + 86400000))

export function DateSelector({ date, setDate }) {
    const dates = [
        { date: yesterdayDate.substring(5), value: yesterdayDate },
        { date: "Today: " + todayDate.substring(5), value: todayDate },
        { date: tomorrowDate.substring(5), value: tomorrowDate }
    ]

    return (
        <div className="card flex flex-column mb-2">
            <SelectButton id="select-date" value={date} onChange={(e) => setDate(e.value)} optionLabel="date" options={dates} />
        </div>
    );
}