import { Panel } from 'primereact/panel';
import { MainMenuBar } from "../components/MainMenuBar";

export default function ContactLanding() {
    return (
        <>
            <MainMenuBar />
            <SocialMedia />
            <Email />
        </>
    )
}

function SocialMedia() {
    return (
        <Panel header="Social Media">
            <p className="m-0">
                Please follow me on social media for latest update on Airgeek, what's coming next and what's on top of my mind.
            </p>
            <a href="https://twitter.com/airgeek96642400">
                <i className='pi pi-twitter m-2' style={{ fontSize: '2rem' }}></i>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61550268232318">
                <i className='pi pi-facebook m-2' style={{ fontSize: '2rem' }}></i>
            </a>
        </Panel>
    )
}

function Email() {
    return (
        <Panel header="Email">
            <p className="m-0">
                Also feel free to email me at <b>xyinblue@gmail.com</b>
            </p>
        </Panel>
    )
}