export class RouteDataReturnModel {
    airline;
    seatTotal;
    seatPercentage;
    flightTotal;
    flightPercentage;
    flightList;

    constructor(airline, seatTotal, flightTotal, flightList) {
        this.airline = airline;
        this.seatTotal = seatTotal;
        this.flightTotal = flightTotal;
        this.flightList = flightList;
    }
}

export const RouteDataMarketShareTableColumnModel = [
    {
        header: "Airline",
        field: "airline"
    },
    {
        header: "Seat Total",
        field: "seatTotal"
    },
    {
        header: "Seat %",
        field: "seatPercentage"
    },
    {
        header: "Flight Total",
        field: "flightTotal"
    },
    {
        header: "Flight %",
        field: "flightPercentage"
    }
]