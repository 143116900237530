import React, { useEffect, useState } from "react";
import { Checkbox } from 'primereact/checkbox';
import { useAuth0 } from "@auth0/auth0-react";

export function FunFlightTableFilter({ data, setFilteredData }) {
    const { isAuthenticated } = useAuth0();

    const [filterSelection, setFilterSelection] = useState({
        departureOnly: false,
        arrivalOnly: false,
        excludeDeparted: false
    });

    useEffect(() => {
        var filteredData = data
        if (filterSelection.departureOnly) {
            filteredData = filteredData.filter((flight) => flight.direction === 'departure');
        }
        if (filterSelection.arrivalOnly) {
            filteredData = filteredData.filter((flight) => flight.direction === 'arrival');
        }
        if (filterSelection.excludeDeparted) {
            filteredData = filteredData.filter((flight) => flight.status !== 'Departed');
        }
        setFilteredData(filteredData)
    }, [filterSelection])

    return (
        <>
            {!isAuthenticated && <div className="mt-2"><i className="pi pi-lock"></i>
                <b>Log in Required to use filters</b>
            </div>}
            <div className="flex flex-wrap row-gap-1 column-gap-3 mt-2 " style={{ fontSize: '13px' }}>
                <div className="flex align-items-center">
                    <Checkbox inputId="departureOnly" name="filterSelection" value="departureOnly" onChange={handleFilterChange} checked={filterSelection.departureOnly} disabled={!isAuthenticated} />
                    <label htmlFor="departureOnly" className="ml-2 mb-0">Departure Only</label>
                </div>
                <div className="flex align-items-center">
                    <Checkbox inputId="arrivalOnly" name="filterSelection" value="arrivalOnly" onChange={handleFilterChange} checked={filterSelection.arrivalOnly} disabled={!isAuthenticated} />
                    <label htmlFor="arrivalOnly" className="ml-2 mb-0">Arrival Only</label>
                </div>
                <div className="flex align-items-center">
                    <Checkbox inputId="excludeDeparted" name="filterSelection" value="excludeDeparted" onChange={handleFilterChange} checked={filterSelection.excludeDeparted} disabled={!isAuthenticated} />
                    <label htmlFor="excludeDeparted" className="ml-2 mb-0">Exclude Departed</label>
                </div>
            </div>
        </>
    )

    function handleFilterChange(e) {
        if (e.checked) {
            setFilterSelection((prevSelection) => {
                const currentSelection = { ...prevSelection };
                currentSelection[e.value] = true;
                return currentSelection;
            })
        } else {
            setFilterSelection((prevSelection) => {
                const currentSelection = { ...prevSelection };
                currentSelection[e.value] = false;
                return currentSelection;
            })
        }
    }
}

