import React, { useState } from "react";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { useAuth0 } from "@auth0/auth0-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlaneArrival, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons'
import { LogInButton } from "../components/LogInButton";


export function FlightTable({ data, includeGateRunway }) {
    const [expandedRows, setExpandedRows] = useState(null);
    const { isAuthenticated } = useAuth0();

    return (
        <DataTable value={data} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={flightDetailTemplate} tableStyle={{ minWidth: '30rem' }} className="mt-2">
            <Column expander={true} style={{ width: '5rem' }} />
            <Column field="flightNumber" header="Flight" body={flightBodyTemplate} sortable sortField="flightNumber" />
            <Column field="category" header="Category" body={categoryBodyTemplate} sortable></Column>
            <Column field="departureOrArrivalTime" header="Time" sortable />
            <Column field="gate" header="Gate" body={gateBodyTemplate(includeGateRunway, isAuthenticated)} sortable />
        </DataTable>
    )
}

const flightBodyTemplate = (rowData) => {
    return (
        <div className="flex flex-column align-items-left gap-2">
            <div className="flex align-items-center gap-3" style={{ whiteSpace: 'nowrap' }}>
                <b>{rowData?.flightNumber}</b>
                {rowData.direction === "departure" && <FontAwesomeIcon icon={faPlaneDeparture} />}
                {rowData.direction === "arrival" && <FontAwesomeIcon icon={faPlaneArrival} />}
            </div>
            <div className="flex align-items-left gap-2" style={{ whiteSpace: 'nowrap' }}>
                <Tag value={rowData.originOrDestination} severity="success"></Tag>
                <Tag value={rowData.aircraft.replace('Boeing', '').replace("Airbus", '').replace("Embraer", 'E').replace(" ", '')} severity="success"></Tag>
            </div>
        </div>
    );
};

const categoryBodyTemplate = (rowData) => {
    if (rowData.livery && rowData.reg) {
        return liveryBodyTemplate(rowData)
    } else if (rowData.category === 'delayed') {
        return delayBodyTemplate(rowData)
    } else {
        return (
            <Tag value={rowData.category} severity="info"></Tag>
        )
    }
}

const flightDetailTemplate = (rowData) => {
    const fr24reg = `https://flightaware.com/live/flight/${rowData.reg}`
    const fr24flight = `https://airportinfo.live/flight/${rowData.flightNumber.replace(" ", "")}`
    return (
        <div className="flex flex-column align-items-left gap-2">
            <div className="flex align-items-center gap-1">
                <b>Registration:  </b>{rowData.reg}
                {rowData.reg && <a href={fr24reg} target="_blank">
                    <i className="pi pi-external-link ml-2"></i>
                </a>}
            </div>
            <div className="flex align-items-center gap-1">
                <b>Status: </b>{rowData.status}
                <a href={fr24flight} target="_blank">
                    <i className="pi pi-external-link ml-2"></i>
                </a>
            </div>
        </div>
    );
};

const gateBodyTemplate = (rowData, includeGateRunway, isAuthenticated) => {
    if (includeGateRunway) {
        return rowData.gate;
    } else {
        return <>{!isAuthenticated && <div className="p flex align-items-center gap-2" style={{ whiteSpace: 'nowrap' }}>
            <LogInButton size="small" /> and select criteria
        </div>}</>;
    }
}

const liveryBodyTemplate = (rowData) => {
    const liveryLink = `https://planespotters.net/search?q=${rowData.reg}`
    return (
        <div className="flex flex-column align-items-left gap-1">
            <p style={{ fontSize: '11px', margin: '0' }}>{rowData.livery}</p>
            <div className="flex align-items-center">
                <Tag value={rowData.category} severity="info" style={{ maxWidth: '60px' }}></Tag>
                <a href={liveryLink} target="_blank">
                    <i className="pi pi-external-link ml-2"></i>
                </a>
            </div>
        </div>
    )
}

const delayBodyTemplate = (rowData) => {
    return (
        <div className="flex flex-column align-items-left gap-1">
            <p style={{ fontSize: '11px', margin: '0' }}>{rowData.status}</p>
            <div className="flex align-items-center">
                <Tag value={rowData.category} severity="info" style={{ maxWidth: '60px' }}></Tag>
            </div>
        </div>
    )
}



