import React, { useState } from "react";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';

import { RouteDataMarketShareTableColumnModel } from '../dataModels/RouteMarketShareDataModel';

export const MarketShareTable = ({ data }) => {
    const [expandedRows, setExpandedRows] = useState(null);
    return (
        <DataTable value={data} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={airlineDetailTemplate} tableStyle={{ minWidth: '30rem' }} className="mt-2">
            <Column expander={true} style={{ width: '5rem' }} />
            <Column field="airline" header="Airline" body={airlineBodyTemplate} sortable sortField="seatTotal" />
        </DataTable>
    )
}

const airlineBodyTemplate = (rowData) => {
    return (
        <div className="flex flex-column align-items-left gap-2">
            <div><b>{rowData.airline}</b></div>
            <div className="flex align-items-center gap-4">
                <Tag value={"Seats: " + rowData.seatTotal + " - " + rowData.seatPercentage + "%"} severity="success"></Tag>
                <Tag value={"Flights: " + rowData.flightTotal + " - " + rowData.flightPercentage + "%"} severity="info"></Tag>
            </div>
        </div>
    );
};

const airlineDetailTemplate = (rowData) => {
    return (
        <ul>
            {rowData.flightList.map(item => {
                return <li key={item}>{item}</li>;
            })}
        </ul>
    )
};

