import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import { Tooltip } from 'primereact/tooltip';


import { AirportInput } from "./AirportInput"
import { DateSelector } from "./DateSelector"
import { SubmitButton } from "./SubmitButton"

export function RouteMaretShareCard({ setDep, setArr, date, setDate, searchInProgress, handleSubmitClick, searchError }) {
    return (
        <div className="card justify-content-center">
            <Card title="Explore Route Market Share" subTitle="Search for the market share of a route between 2 cities" >
                <ExplainText></ExplainText>
                <SearchInput setDep={setDep} setArr={setArr} date={date} setDate={setDate} />
                <SubmitButton searchInProgress={searchInProgress} handleSubmitClick={handleSubmitClick} />
                {searchError && <Message severity="error" text={searchError} className="mt-2" />}
            </Card>
        </div>
    )
}

function ExplainText() {
    const explainRouteMarketShareTooltipContent = "The seat count is calculated by adding together the rough number of seats per aircraft type, not the precious seat count based on the exact aircraft"

    return (
        <div className="flex align-items-center">
            <p>How are the numbers calculated?</p>
            <i className="explain-icon pi pi-question-circle ml-2" data-pr-position="right" data-pr-at="right+5 top" data-pr-tooltip={explainRouteMarketShareTooltipContent}></i>
            <Tooltip target=".explain-icon" />
        </div>
    )
}

function SearchInput({ setDep, setArr, date, setDate }) {
    return (
        <div className="flex flex-wrap column-gap-2 row-gap-3 container">
            <DateSelector date={date} setDate={setDate} />
            <AirportInput setCity={setDep} title="Departure airport" />
            <AirportInput setCity={setArr} title="Arrival airport" />
        </div>
    )
}