import { useNavigate } from 'react-router-dom';

import { Menubar } from 'primereact/menubar';
import { LogInButton } from '../components/LogInButton';
import { useAuth0 } from "@auth0/auth0-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlane } from '@fortawesome/free-solid-svg-icons'

export function MainMenuBar() {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth0();

    const items = [
        {
            label: 'Airgeek, made by AvGeek',
            icon: <FontAwesomeIcon icon={faPlane} size="xl" className='mr-1'/>,
            command: () => { navigate('/') }
        },
        {
            label: 'Fun Tools',
            items: [
                {
                    label: 'Fun Flight',
                    command: () => { navigate('/funflight') }
                },
                {
                    label: 'Route Market Share',
                    command: () => { navigate('/routemarketshare') }
                }
            ]
        },
        {
            label: "About",
            items: [
                {
                    label: 'About Airgeek',
                    command: () => { navigate('/about') }
                },
                {
                    label: 'Roadmap',
                    command: () => { navigate('/roadmap') }
                },
                {
                    label: 'Contact',
                    command: () => { navigate('/contact') }
                }

            ]
        }
    ]

    const menuEndSection = <>{!isAuthenticated &&
        <LogInButton></LogInButton>}</>

    return (
        <div className="card">
            <Menubar model={items} end={menuEndSection}>
            </Menubar>
        </div>
    )
}