import axios from 'axios'
import { API } from 'aws-amplify'

const init = {
    headers: { "X-API-Key": "JshTIbjMqG3WPJbhgEIxS30W5iYLw3VIs635rF1e" }
}

export async function get(myApi, requestUri) {
    try {
        const response = await API.get(myApi, requestUri, init);
        // local 
        // const response = (await axios.get("http://localhost:3080" + getPath)).data;
        console.log('response:  ', response)
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function post(myApi, requestUri, requestBody) {
    try {
        var payload = { body: requestBody, headers: init.headers }
        const response = await API.post(myApi, requestUri, payload);
        // local 
        // const response = (await axios.post("http://localhost:3080" + requestUri, requestBody, init)).data;
        console.log('response:  ', response)
        return response;
    } catch (error) {
        console.log(error);
    }
}