import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';

import { useNavigate } from 'react-router-dom';
import { MainMenuBar } from "../components/MainMenuBar";

export default function MainLanding() {
    return (
        <>
            <MainMenuBar />
            <WelcomeCard />
            <WhatsNewCard />
            <ToolsCardSection />
        </>
    )
}

function WelcomeCard() {
    return (
        <div className="card justify-content-center mt-2 mb-3">
            <Card title="Welcome to Airgeek!" >
                The most comprehensive toolset on the market to see all the hidden insights on aviation. Explore aviation like you have never been before on any other sites with the listed tools below.
            </Card>
        </div>
    )
}

function WhatsNewCard() {
    const liveryList = loadNewLivery().sort((a, b) => a.localeCompare(b));
    return (
        <div className="card justify-content-center mb-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <Panel header={whatsNewHeaderTemplate} toggleable collapsed={true}>

                <div className="flex-wrap" style={{ lineHeight: '1' }}>
                    {liveryList.map((item, index) => (
                        <p key={index}>{item}</p>
                    ))}
                </div>
            </Panel>
        </div>
    )
}

function ToolsCardSection() {
    return (
        <div className="flex flex-wrap gap-3">
            <Card style={{ maxWidth: '400px' }} title="Fun Flight" subTitle="Explore fun flights" footer={<ToolCardFooter urlPath="funflight" />}>
                Search for flights with special livery or wide body; check out newly started flight from an airport; find out about all cancelled and delayed flights, and much more.
            </Card>
            <Card style={{ maxWidth: '400px' }} title="Route Market Share" subTitle="Market share from airport A to airport B" footer={<ToolCardFooter urlPath="routemarketshare" />}>
                Explore the market share between 2 airports by airlines, measured by seat count and flight count.
            </Card>
            {/* <Card style={{ maxWidth: '400px' }} title="Past Gates" subTitle="See the past gates of a flight" footer={<ToolCardFooter urlPath="pastgates" />}>
                Explore the departure gates of a specific flight in the past few days.
            </Card> */}
        </div>
    )
}

function ToolCardFooter({ urlPath }) {
    const navigate = useNavigate();
    return (
        <div className="flex flex-wrap justify-content-end gap-2">
            <Button label="Try Now" icon="pi pi-arrow-up-right" onClick={() => navigate(urlPath)} />
        </div>
    )
}

function whatsNewHeaderTemplate() {
    return (
        <div>
            <div className='flex align-items-center'>
                <i className="pi pi-heart-fill mr-2"></i>
                <p> What's New </p>
            </div>
            <p>
                16 Special livery released in September (expand to see the list)
            </p>
        </div>
    )
}

function loadNewLivery() {
    const textData = `
    USA    Alaska Airlines    Embraer ERJ175LR    N662QX    Washington Huskies American Football Team
    China    China Express Airlines    Airbus A320neo    B-328G    Fenjiu, Hometown of 'Heroes of Luliang'
    Germany    Eurowings    Airbus A320    D-AEWP    Salzburger Land
    South Africa    FlySafair    Boeing 737-800    ZS-SJU    Springboks Rugby Union Team
    China    Xiamen Airlines    Boeing 737-800    B-5487    Universal Beijing Resort
    Afghanistan    Kam Air    Boeing 737-500    YA-KMN    30 Years In The Sky
    Mexico    Viva Aerobus    Airbus A321    XA-VBV    Juan Gabriel
    Chile    LATAM Airlines Chile    Boeing 787-9    CC-BGS    Pan-American Games Santiago 2023
    USA    Alaska Airlines    Embraer ERJ175LR    N661QX    Washington State Cougars American Football Team
    China    Juneyao Airlines    Airbus A320neo    B-326J    Fuping Dried Persimmon
    Brazil    Azul Linhas Aereas    Embraer ERJ195-E2    PS-AEO    Santos Dumont 150 Years
    Haiti    Sunrise Airways    Embraer EMB-120    ZS-JWD    ONE Caribbean
    Japan    Peach    Airbus A320    JA825P    'Barbie' movie
    USA    American Airlines    Airbus A321    N581UW    Piedmont Heritage Retro
    China    China Southern Airlines    Boeing 737-800    B-5598    CAEXPO
    USA	American Airlines	Airbus A321	N582UW	PSA Heritage Retro
    `;

    // Split the text into lines
    const lines = textData.trim().split('\n');

    return lines;
}