import { Button } from 'primereact/button';
import { useAuth0 } from "@auth0/auth0-react";
import "../index.css"

export const LogInButton = ({ size }) => {
  const { loginWithRedirect } = useAuth0();

  if (size) {
    return <Button label="Log In" severity="secondary" onClick={() => handleClick()} className='login-button-mini'>
    </Button>;
  }
  return <Button label="Sign Up / Log In" severity="secondary" onClick={() => handleClick()}>
  </Button>;

  function handleClick() {
    return loginWithRedirect({
      appState: { returnTo: window.location.href}
    })
  }
};

