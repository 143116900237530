import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes, Switch, Redirect, Router } from "react-router-dom";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import { Auth0Provider } from "@auth0/auth0-react";

import axios from 'axios'

import { Amplify, API } from 'aws-amplify';

// File import
import FlightLanding from "./landings/FlightLanding.js";
import MainLanding from "./landings/MainLanding.js";
import RouteMaretShareLanding from "./landings/RouteMarketShareLanding.js";
import AboutLanding from "./landings/AboutLanding.js";
import ContactLanding from "./landings/ContactLanding.js";
import RoadmapLanding from "./landings/RoadmapLanding.js";
// import AboutMeLanding from "./views/AboutMeLanding.js";
// import FeaturesLanding from "./views/FeaturesLanding";

const root = ReactDOM.createRoot(document.getElementById("root"));
axios.defaults.baseURL = process.env.BACKEND_URL;


Amplify.configure({
  API: {
    endpoints: [
      {
        name: "FlightService",
        endpoint: "https://c2t7wnh49a.execute-api.us-east-1.amazonaws.com/dev"
      }
    ]
  }
});

root.render(
  <BrowserRouter>

    <Auth0Provider
      // dev
      // domain="dev-p9e48hfz.us.auth0.com"
      // clientId="1XdOxJsEXVWmPOE6tBAPNXNk3zYKvLj7"
      // prod
      domain="airgeek.us.auth0.com"
      clientId="Gz1K4nGzf7f7Hi4r0Ps8n3MHQR9A4KSW"
      redirectUri={window.location.href}
      useRefreshTokens={true}
      cacheLocation="localstorage">
      <Routes>
        <Route
          path="/"
          exact
          element={<MainLanding />}>
        </Route>
        <Route
          path="/funflight"
          exact
          element={<FlightLanding />}>
        </Route>
        <Route
          path="/routemarketshare"
          exact
          element={<RouteMaretShareLanding />}>
        </Route>
        <Route
          path="/about"
          exact
          element={<AboutLanding />}>
        </Route>
        <Route
          path="/roadmap"
          exact
          element={<RoadmapLanding />}>
        </Route>
        <Route
          path="/contact"
          exact
          element={<ContactLanding />}>
        </Route>
      </Routes>
    </Auth0Provider>
  </BrowserRouter>
);
